.msab-popup-cdn {
  font-family: WorkSans-Regular, sans-serif;
  border-radius: 3px;
  background: var(--popupBackground);
  text-align: center !important;
  padding: 0px 0px 10px 0px !important;
  width: 100vw !important;
  //margin-bottom: 50px;
  font-size: 18px;
  z-index: 2000 !important;

  .ant-notification-notice-close-x {
    visibility: hidden !important;
  }

  .ant-notification-notice-description {
    color: white !important;
  }
}

.swal2-popup {
  font-family: 'WorkSans-Regular', sans-serif !important;
}
.swal2-title {
  font-family: 'Montserrat-SemiBold', sans-serif;
}
